import {of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {httpContextDefaults} from "@app/shared/constants";
import {HttpEvent, HttpInterceptorFn, HttpResponse} from '@angular/common/http';

const cache = new Map<string, any>();

export const cacheInterceptor: HttpInterceptorFn = (req, next) => {
  const params = req.context.get(httpContextDefaults);

  if (params.cache) {
    if (req.method !== 'GET') {
      return next(req);
    }

    const cachedResponse = cache.get(req.url);

    if (cachedResponse) {
      return of(cachedResponse);
    }

    return next(req)
      .pipe(
        tap((event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            cache.set(req.url, event);
          }
        })
      );
  }

  return next(req);
}

