import {Component, inject} from '@angular/core';
import {AppStateService} from "@app/shared/services";
import {ChoosePlanComponent} from "@app/layouts/account/pages";
import {FAQSectionComponent} from "@app/layouts/main-layout/pages/sections";

@Component({
  selector: 'sf-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss'],
  imports: [
    ChoosePlanComponent,
    FAQSectionComponent
  ],
  standalone: true
})
export class PricingPageComponent {
  public readonly appStateService = inject(AppStateService);
}
