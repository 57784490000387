<sf-header/>

<div class="contact-us-container">
  <h1>{{ 'contact_us_page.contact_us' | translate}}</h1>

  <div class="c-as-child-container">

    <div class="c-us-part">
      <p class="mb-20">{{ 'contact_us_page.support' | translate }}</p>

      <div class="mb-20">
        <h2 class="fw-600">{{ 'contact_us_page.location' | translate }}</h2>
        <p>Nikoghayos Tigranyan blind alley 1 , Armenia, Yerevan, 0014</p>
      </div>

      <p class="mb-20">
        <a class="sf-link" href="mailto: support@selfcv.com">support&#64;selfcv.com</a>
      </p>

      <p>
        <sf-project-social-icons/>
      </p>
    </div>

    <div class="c-us-part">
      <form
        [formGroup]="contactUsForm"
        #form="ngForm"
        (ngSubmit)="onSubmit()"
        (keydown.enter)="$event.preventDefault()">
        <div class="inputs-part">
          <div class="side-to-side-inputs">

            <div class="sf-input-wr">
              <input
                class="sf-input small"
                type="text"
                [placeholder]="'placeholder.full_name' | translate"
                formControlName="fullName"
                sfTrim
                [class.error]="form.submitted && contactUsForm.controls['fullName'].errors">

              @if (form.submitted && contactUsForm.controls['fullName'].errors; as error) {
                <div class="sf-err-wr">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    {{ 'validation.full_name_required' | translate }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.full_name_max_length'| translate:{
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>
                </div>
              }
            </div>

            <div class="sf-input-wr">
              <input
                class="sf-input small"
                type="text"
                [placeholder]="'placeholder.email' | translate"
                formControlName="email"
                sfTrim
                [class.error]="form.submitted && contactUsForm.controls['email'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && contactUsForm.controls['email'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.email_required' | translate }}
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.email_max_length'| translate:{
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>

                <div class="sf-input-err" *ngIf="error['email']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.incorrect_email' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="sf-input-wr mb-20">
            <input
              class="sf-input small"
              type="text"
              [placeholder]="'placeholder.subject' | translate"
              formControlName="subject"
              sfTrim
              [class.error]="form.submitted && contactUsForm.controls['subject'].errors">

            <div class="sf-err-wr" *ngIf="form.submitted && contactUsForm.controls['subject'].errors as error">
              <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                <i class="ph ph-warning"></i>
                {{
                  'validation.subject_max_length'| translate:{
                    requiredLength: length["requiredLength"],
                    actualLength: length["actualLength"]
                  }
                }}
              </div>
            </div>
          </div>

          <div class="sf-input-wr mb-20">
            <textarea
              class="sf-textarea"
              type="text"
              [placeholder]="'placeholder.message' | translate"
              formControlName="message"
              sfTrim
              [class.error]="form.submitted && contactUsForm.controls['fullName'].errors"
            ></textarea>

            <div class="sf-err-wr" *ngIf="form.submitted && contactUsForm.controls['fullName'].errors as error">
              <div class="sf-input-err" *ngIf="error['required']">
                <i class="ph ph-warning"></i>
                {{ 'validation.message_required' | translate }}
              </div>

              <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                <i class="ph ph-warning"></i>
                {{
                  'validation.message_max_length'| translate:{
                    requiredLength: length["requiredLength"],
                    actualLength: length["actualLength"]
                  }
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="button-part">
          <div class="btn-content">
            <div class="right-side">
              <button class="sf-btn-primary">{{ 'contact_us_page.send_message' | translate }}</button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>

</div>

<sf-footer/>
