import {throwError} from 'rxjs';
import {inject} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {httpContextDefaults} from "@app/shared/constants";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {HttpErrorResponse, HttpEvent, HttpInterceptorFn, HttpResponse} from '@angular/common/http';

/*@Injectable()
export class NotifierInterceptor implements HttpInterceptor {

  private readonly nzNotificationService = inject(NzNotificationService);

  public intercept(req: HttpRequest<Params>, next: HttpHandler): Observable<HttpEvent<Params>> {
    const params = req.context.get(httpContextDefaults);

    return next.handle(req)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (params.notifySuccess) {
              this.nzNotificationService.success('Success', 'Changes Successfully saved!');
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (params.notifyError) {
            if (error?.error?.reason) {
              let message = 'Something weird happened, please try later!';
              this.nzNotificationService.error('Error', error.error.reason);
            }
          }
          return throwError(error);
        })
      );
  }

}*/


export const notifierInterceptor: HttpInterceptorFn = (req, next) => {
  const nzNotificationService = inject(NzNotificationService);
  const params = req.context.get(httpContextDefaults);

  return next(req).pipe(
    tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && params?.notifySuccess) {
        nzNotificationService.success('Success', 'Changes Successfully saved!');
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (params?.notifyError && error?.error?.reason) {
        nzNotificationService.error('Error', error.error.reason || 'Something weird happened, please try later!');
      }
      return throwError(() => error);
    })
  );
};
