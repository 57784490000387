import {environment} from "@env";
import {filter, finalize} from "rxjs/operators";
import {HelperComponent} from "@app/shared/ui-kits";
import {TranslateService} from "@ngx-translate/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzModalModule, NzModalService} from "ng-zorro-antd/modal";
import {DOCUMENT, NgOptimizedImage, ViewportScroller} from "@angular/common";
import {AnalyticsService} from "@app/shared/services/analytics.service";
import {Component, DestroyRef, inject, OnInit, Renderer2} from '@angular/core';
import {parseJwt, parseQueryParams, removeQueryParams, updateCanonicalURL} from "@app/shared/helpers";
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {AppStateService, AuthService, PaymentService, TemplateService} from "@app/shared/services";
import {
  AmeriaBankQueryParams,
  AmeriaPaymentConfirmRes,
  JWT,
  MySocialUser,
  Template,
  User
} from "@app/shared/interfaces";
import {Meta, Title} from "@angular/platform-browser";

// declare const Paddle: any;
declare let gtag: Function;
declare const google: any;

interface SEO_Config {
  title: string;
  description: string;
  keywords: string;
  url: string;
  content: string;
  og: {
    title: string;
    desc: string;
    local: string;
  }
}

interface SEO_Configs {
  en: SEO_Config;
  ru: SEO_Config;
}

const SEO_Configs: SEO_Configs = {
  en: {
    title: 'elfcv | Free Resume Builder',
    description: `Build your professional CV online with our easy-to-use CV builder.
                  Stand out with custom templates, expert tips, and tailored guidance for job success.`,
    keywords: `SelfCv, self cv, Self-CV, selfcv, selfCV, cv generator, free cv generator, online free cv generator,
               CV builder, resume builder, online CV, professional resume, custom templates, CV tips, build cv for free,
               build cv online for free, resume generator for free`,
    url: "https://www.selfcv.com",
    content: 'en_US',
    og: {
      title: 'Resume Builder For 2024 | Free CV Builder | selfcv',
      desc: `Create the Perfect Resume or CV and Land Your Dream Job with Our Free Resume Builder. Select a template.
             Personalize it. Apply to jobs. Get more interviews.`,
      local: `en_US`,
    }
  },
  ru: {
    title: "selfcv | Бесплатный Конструктор Резюме",
    description: `Создайте профессиональное резюме онлайн с помощью нашего удобного конструктора резюме.
                    Выделяйтесь с индивидуальными шаблонами, советами экспертов и персональными рекомендациями для успеха в поиске работы.`,
    keywords: `SelfCv, self cv, Self-CV, selfcv, selfCV, генератор резюме, бесплатный генератор резюме, онлайн генератор резюме бесплатно,
                 конструктор резюме, конструктор CV, онлайн резюме, профессиональное резюме, индивидуальные шаблоны, советы по резюме, создание резюме бесплатно,
                 создание резюме онлайн бесплатно, бесплатный генератор резюме`,
    url: "https://www.selfcv.com/ru_RU",
    content: "ru_RU",
    og: {
      title: "Конструктор Резюме 2024 | Бесплатный Конструктор CV | selfcv",
      desc: `Создайте идеальное резюме или CV и получите работу своей мечты с нашим бесплатным конструктором резюме. Выберите шаблон.
                 Персонализируйте его. Откликайтесь на вакансии. Получайте больше приглашений на собеседования.`,
      local: "ru_RU"
    }
  }
};

@Component({
  selector: 'sf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterOutlet,
    NgOptimizedImage,
    NzModalModule,
    NzDropDownModule,
    HelperComponent,
  ],
  standalone: true
})
export class AppComponent implements OnInit {
  private readonly metaService = inject(Meta);
  private readonly titleService = inject(Title);
  private readonly document = inject(DOCUMENT);

  protected readonly router = inject(Router);
  private readonly renderer = inject(Renderer2);
  protected readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly paymentService = inject(PaymentService);
  // private readonly paymentService = inject(PaymentService);
  private readonly nzModalService = inject(NzModalService);
  private readonly appStateService = inject(AppStateService);
  private readonly templateService = inject(TemplateService);
  private readonly translateService = inject(TranslateService);
  private readonly viewportScroller = inject(ViewportScroller);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly nzMessageService = inject(NzMessageService);

  constructor() {
  }

  ngOnInit() {
    this.listenToInternetConnection();

    this.translateService.setDefaultLang('en');
    this.translateService.use(this.appStateService.selectedLanguage.key);
    this.appStateService.init_constantsForTranslate();

    this.getMe();

    // this.getAPIAddress();
    // this.getPaymentPrices();
    // this.getTemplatesToSelect();

    this.listenToUserLogin();
    this.listenToRouterEvents();

    this.confirmAmeriaBankPayment();
    this.getUserLocation();

    setTimeout(() => {
      this.initializeGoogleSignIn();
    }, 5000);

    // Listen for language changes
    this.translateService.onLangChange.subscribe((event) => {
      console.log('lng change event', event);
      this.setHtmlLangAttribute(event.lang);
      this.addMetaTags(SEO_Configs[event.lang as keyof SEO_Configs], this.renderer);
    });

    this.setHreflangTags();

    this.addMetaTags(SEO_Configs[this.appStateService.selectedLanguage.key as keyof SEO_Configs], this.renderer);
  }

  private addMetaTags(project: SEO_Config, renderer: Renderer2) {
    console.log('addMetaTags', project);

    const title = project.title || project.og.title;
    const desc = project.description || project.og.desc;
    const imgUrl = 'https://selfcv.s3.eu-central-1.amazonaws.com/utils/main-banner.png';
    const url = project.url;

    this.titleService.setTitle(project.title);

    /* Update meta tags */
    this.metaService.updateTag({name: 'description', content: project.description});
    this.metaService.updateTag({name: 'keywords', content: project.keywords});

    /* Schema.org markup for Google+ */
    /** Interops are duplicated not replaces (Bug) */
    // this.metaService.updateTag({itemprop: 'name', content: title});
    // this.metaService.updateTag({itemprop: 'description', content: desc});
    // this.metaService.updateTag({itemprop: 'image', content: imgUrl});

    /*  Open Graph data  */
    this.metaService.updateTag({property: 'og:title', content: title});
    this.metaService.updateTag({property: 'og:locale', content: project.og.local});
    this.metaService.updateTag({property: 'og:description', content: desc});
    this.metaService.updateTag({property: 'og:url', content: url});
    this.metaService.updateTag({property: 'og:image', content: imgUrl});
    this.metaService.updateTag({property: 'og:image:alt', content: 'selfcv'});

    /*  Twitter Card data  */
    this.metaService.updateTag({name: 'twitter:site', content: url});
    this.metaService.updateTag({name: 'twitter:title', content: title});
    this.metaService.updateTag({name: 'twitter:description', content: desc});
    this.metaService.updateTag({property: 'twitter:image:src', content: imgUrl});

    /* Other */
    this.metaService.updateTag({name: 'msapplication-TileImage', content: imgUrl});

    updateCanonicalURL(url, this.document, renderer);
  }

  private setHreflangTags() {

    const LANGUAGES = [
      {lang: 'en', url: 'https://www.selfcv.com'},
      {lang: 'ru', url: 'https://www.selfcv.com/ru'},
    ];

    // Remove existing hreflang tags to avoid duplicates
    const existingTags = document.querySelectorAll("link[rel='alternate'][hreflang]");
    existingTags.forEach(tag => tag.remove());

    // Loop through defined languages and add hreflang tags
    LANGUAGES.forEach(language => {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'alternate');
      this.renderer.setAttribute(link, 'hreflang', language.lang);
      this.renderer.setAttribute(link, 'href', language.url);
      this.renderer.appendChild(document.head, link);
    });

    // Add x-default tag
    const xDefaultLink = this.renderer.createElement('link');
    this.renderer.setAttribute(xDefaultLink, 'rel', 'alternate');
    this.renderer.setAttribute(xDefaultLink, 'hreflang', 'x-default');
    this.renderer.setAttribute(xDefaultLink, 'href', 'https://www.selfcv.com');
    this.renderer.appendChild(document.head, xDefaultLink);
  }

  private setHtmlLangAttribute(lang: string) {
    // Update the <html> tag's lang attribute
    this.renderer.setAttribute(document.documentElement, 'lang', lang);
  }

  private getUserLocation() {
    this.paymentService.getUserLocation()
      .pipe(
        finalize(() => this.appStateService.isUserPlansReady = true),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((res) => {
        console.log('user location =>', res);
        this.appStateService.userLocation = res;
        this.appStateService.userLocationInit$.next(res);
        this.analyticsService.track('User location', res);
      });
  }

  private confirmAmeriaBankPayment() {
    if (this.authService.isAuthenticated) {
      const queryParams: AmeriaBankQueryParams = parseQueryParams(window.location.search);

      if (queryParams) {

        const condition = queryParams.hasOwnProperty('paymentID') &&
          ['resposneCode', 'responseCode'].some((item) => queryParams.hasOwnProperty(item));

        if (condition) {
          this.paymentService.confirmAmeriaPayment(queryParams.paymentID)
            .pipe(
              finalize(() => removeQueryParams(this.router)),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((res: AmeriaPaymentConfirmRes) => {
              this.nzMessageService.create(res.status.toLowerCase(), res.description);
              this.appStateService.user!.planIsValid = res.planIsValid;
              this.appStateService.user!.activeUntil = res.activeUntil;
              this.analyticsService.track("Payment Confirmed!!!", res);
              this.getMe();
            });
        }
      }

    }
  }

  private listenToInternetConnection() {
    window.addEventListener('online', () => {
      this.nzMessageService.success(
        this.translateService.instant('project_messages.you_online')
      );
    });

    window.addEventListener('offline', () => {
      this.nzMessageService.warning(
        this.translateService.instant('project_messages.you_offline')
      );
    });
  }

  private listenToUserLogin() {
    this.appStateService.user$
      .pipe(
        filter((user) => Boolean(user))
      )
      .subscribe(() => {
        this.getUserTemplates();
      });
  }

  private listenToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.scrollToTop();

        if (environment.env === 'production') {
          this.trackEvent(event as NavigationEnd);
        }

        this.nzModalService.closeAll();
      });
  }

  private scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
    document.body.scrollTop = 0;

    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTo(0, 0);
    }, 100);

    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
  }

  private trackEvent(event: NavigationEnd) {
    gtag('set', 'page_path', event.urlAfterRedirects);
    gtag('event', 'page_view');
  }

  private getMe() {
    if (this.authService.isAuthenticated || this.appStateService.onPrintPage) {
      this.authService.getMe()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: User) => {
          this.appStateService.user = res;
          this.appStateService.user$.next(res);
          this.analyticsService.identifyMixpanelUser(res);
          this.getUserTemplates();

          console.log('user', res);
        });
    }
  }

  private getUserTemplates() {
    this.templateService.getTemplates()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Template[]) => {
        console.log('user Templates', res);
        this.appStateService.userTemplates = res;
        this.appStateService.userTemplates$.next(res);
      });
  }

  private initializeGoogleSignIn() {
    if (!this.authService.isAuthenticated) {
      if (!['sign-in', 'sign-up'].some((route) => this.router.url.includes(route))) {
        google.accounts.id.initialize({
          client_id: environment.google_oauth_client_id,
          callback: this.googleSocialSignIn.bind(this)
        });

        google.accounts.id.prompt();
      }
    }
  }

  private googleSocialSignIn(data: { credential: string; select_by: string; }) {
    const user: MySocialUser = {
      credential: data.credential,
      provider: 'google'
    };

    this.authService.socialSignIn(user)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: JWT) => {
          // Adding userId so we can track
          const jwtContent = parseJwt(res.accessToken);
          user.id = jwtContent.userId;
          this.getMe();
          this.router.navigateByUrl(this.returnUrl);
        },
        error: () => {
          this.nzMessageService.error(
            this.translateService.instant('project_messages.social_login_failed')
          );
        }
      });
  }

  /*private getTemplatesToSelect() {
    this.templateService.getTemplatesToSelect()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: Template[]) => {
        // this.appStateService.templates = res;
        console.log(res);
      });
  }*/

  private get returnUrl(): string {
    return this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  /*private getAPIAddress() {
    this.paymentService.getAPIAddress()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        console.log('APIAddress', res);

        /!*interval(500)
          .pipe(
            takeWhile(() => !Paddle, true),
            filter(() => Boolean(Paddle))
          )
          .subscribe(() => {
            this.setUpJSPaddle(res);
          });*!/

      });
  }*/

  /*private getPaymentPrices() {
    this.paymentService.getPaymentPrices()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        console.log('billing prices', res);
        this.appStateService.billingPlans = res.response.products;
      });
  }*/

  /*private setUpJSPaddle(res: string) {
    Paddle.Environment.set("sandbox");

    Paddle.Initialize({
      token: "test_382d3434e3c21706bd450a58a0b",
      eventCallback: (data: any) => {
        console.log("------------data-------------", data);

        if (data.name === 'checkout.completed') {
          this.appStateService.checkoutComplete$.next();
          setTimeout(() => this.getMe(), 3000);
        }


        if (data.name === PaddleEventTypes.LOADED) {
          Paddle.Spinner.hide();
          // set here some variable showCheckoutInfo: true
          // set user email and pass to that form
        }

        if (data.name === PaddleEventTypes.CUSTOMER_CREATED) {
          // Update price based on the price coming with event (total, tax ... see consoled)
          console.log('PaddleEventTypes.CUSTOMER_CREATED', data);
          console.log(data.data);
          console.log(data.data.currency_code);
          console.log(data.data.totals);
        }

        if (data.name === PaddleEventTypes.CUSTOMER_UPDATED) {
          // when user changes the country - this will update the price accordingly
          // because we have some country with different tax and also our custom country prices

          console.log('PaddleEventTypes.CUSTOMER_UPDATED', data);
          console.log(data.data);
          console.log(data.data.currency_code);
          console.log(data.data.totals);
        }

        if (data.name === PaddleEventTypes.CHECKOUT_COUPON_REMOVE) {
          // this must be handled, related coupon
        }

        if (data.name === PaddleEventTypes.PAYMENT_COMPLETE) {
          // Checkout payment completed event
          console.log('PaddleEventTypes.PAYMENT_COMPLETE');
        }

        if (data.name === PaddleEventTypes.COMPLETE) {
          // here we can close checkout form and navigate user to dashboard
          console.log('PaddleEventTypes.COMPLETE', data.eventData); // Data specifics on the event
          this.appStateService.checkoutComplete$.next();
          this.getMe();
        } else if (data.name === PaddleEventTypes.CLOSE) {
          console.log(data.eventData); // Data specifics on the event
        } else if (data.name === PaddleEventTypes.ERROR) {
          // generic errors
          console.log(data.eventData); // Data specifics on the event
        }
      }
    });

    const request: PricePreviewBody = {
      items: pricePreviewBodyItems,
      customerIpAddress: res
    };

    Paddle.PricePreview(request)
      .then((pricePreview: PricePreview) => {
        this.appStateService.pricePreview = pricePreview;
        console.log('pricePreview', pricePreview);
      })
      .catch((error: any) => {
        /!* TODO Armen cover this *!/
        // If not supported country will be passed, there could be errors.
        // It is important to pass supported country only in the paddle payment list.
        // If there is an error, we can pass some default country - for example Germany code (DE).
        console.error('Paddle.PricePreview error', error);
      });
  }*/

}
