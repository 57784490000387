<sf-payment-banner/>

<div class="user-page-main-container">
  <div class="top-part">
    <div class="cover"></div>

    <div class="user-image">
      <ng-container *ngIf="!appStateService.user?.image; else UserImageRef">
        <img ngSrc="assets/img/user-upload.svg" width="122" height="122" alt="selfcv user upload">
        <input type="file" (input)="onSelectImage($event)">
      </ng-container>
    </div>
  </div>

  <div class="form-part">
    <div class="form-top">
      <h4>{{ 'account_page.personal_information' | translate }}</h4>
      <p>{{ 'account_page.your_resume' | translate }}</p>
    </div>

    <form [formGroup]="userFrom" #form="ngForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
      <div class="input-part">
        <div class="sf-input-wr">
          <input
            class="sf-input small"
            type="text"
            [placeholder]="'placeholder.full_name' | translate"
            formControlName="fullName"
            sfTrim
            [class.error]="form.submitted && userFrom.controls['fullName'].errors">

          <div class="sf-err-wr" *ngIf="form.submitted && userFrom.controls['fullName'].errors as error">
            <div class="sf-input-err" *ngIf="error['required']">
              <i class="ph ph-warning"></i>
              {{ 'validation.full_name_required' | translate }}
            </div>

            <div class="sf-input-err" *ngIf="error['maxlength'] as length">
              <i class="ph ph-warning"></i>
              {{
                'validation.full_name_max_length'| translate:{
                  requiredLength: length["requiredLength"],
                  actualLength: length["actualLength"]
                }
              }}
            </div>
          </div>
        </div>

        <div class="sf-input-wr">
          <input
            class="sf-input small readonly"
            type="text"
            [placeholder]="'placeholder.email' | translate"
            [value]="appStateService.user?.email || ''">
        </div>

        <div class="sf-input-wr">
          <nz-date-picker
            [nzShowToday]="false"
            [nzDefaultPickerValue]="initialBirthDayValue"
            [nzDisabledDate]="disabledDate"
            class="sf-date small" formControlName="birthDate"
            [nzPlaceHolder]="'placeholder.birth_date' | translate"
          ></nz-date-picker>
        </div>

        <div class="sf-phone-input small">
          <nz-select
            [nzSize]="'large'"
            nzShowSearch
            nzAllowClear
            [nzDropdownMatchSelectWidth]="false"
            [nzPlaceHolder]="'Code'"
            formControlName="phoneCode">
            <nz-option
              *ngFor="let country of appStateService.countries"
              nzCustomContent
              [nzValue]="country.dialCode"
              [nzLabel]="country.dialCode">
              {{ country.name + ' (' + country.dialCode + ')' }}
            </nz-option>
          </nz-select>
          <input
            class="sf-input"
            type="number"
            [placeholder]="'placeholder.phone_number' | translate"
            formControlName="phoneNumber"
            sfTrim/>
        </div>

        <div class="sf-input-wr">
          <input
            class="sf-input small"
            [placeholder]="'placeholder.country' | translate"
            formControlName="country"
            (input)="onCountryChange($any($event.target).value)"
            [nzAutocomplete]="auto"
            [class.error]="form.submitted && userFrom.controls['country'].errors"/>
          <nz-autocomplete #auto>
            <nz-auto-option
              *ngFor="let country of filteredCountries"
              [nzValue]="country.name"
              [nzLabel]="country.name">
              {{ country.name }}
            </nz-auto-option>
          </nz-autocomplete>

          <div class="sf-err-wr" *ngIf="form.submitted && userFrom.controls['country'].errors as error">
            <div class="sf-input-err" *ngIf="error['maxlength'] as length">
              <i class="ph ph-warning"></i>
              {{
                'validation.country_max_length'| translate:{
                  requiredLength: length["requiredLength"],
                  actualLength: length["actualLength"]
                }
              }}
            </div>
          </div>
        </div>

        <div class="sf-input-wr">
          <input
            class="sf-input small"
            type="text"
            [placeholder]="'placeholder.city' | translate"
            formControlName="city"
            sfTrim
            [class.error]="form.submitted && userFrom.controls['city'].errors">

          <div class="sf-err-wr" *ngIf="form.submitted && userFrom.controls['city'].errors as error">
            <div class="sf-input-err" *ngIf="error['maxlength'] as length">
              <i class="ph ph-warning"></i>
              {{
                'validation.city_max_length'| translate:{
                  requiredLength: length["requiredLength"],
                  actualLength: length["actualLength"]
                }
              }}
            </div>
          </div>
        </div>
      </div>

      <sf-coupon-banner/>

      <div class="checkbox-part">
        <h3>{{'account_page.notifications' | translate}}</h3>

        <div class="checkboxes">
          <div class="sf-checkbox readonly opacity-60">
            <input type="checkbox" id="payment" checked>
            <label for="payment">{{ 'account_page.payment_success' | translate }}</label>
          </div>

          <div class="sf-checkbox">
            <input type="checkbox" id="news" formControlName="subscribeToNotifications">
            <label for="news">{{ 'account_page.news' | translate }}</label>
          </div>

          <div class="sf-checkbox readonly opacity-60">
            <input type="checkbox" id="pass" checked>
            <label for="pass">{{ 'account_page.password_change' | translate }}</label>
          </div>
        </div>
      </div>

      <div class="button-part">
        <div class="btn-content">
          <span class="delete-ac" (click)="onDeleteUser()">{{ 'account_page.delete_account' | translate }}</span>
          <div class="right-side">
            <button class="sf-btn-primary">{{ 'account_page.save_changes' | translate }}</button>
          </div>
        </div>
      </div>

    </form>

  </div>

</div>


<ng-template #UserImageRef>
  <div
    class="user-uploaded-img-wrapper"
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="EditImg">
    <img
      [src]="appStateService.user!.image || ''"
      class="user-uploaded-img"
      width="132"
      height="132"
      alt="selfcv User">
    <input type="file" class="d-none" (input)="onSelectImage($event)" #InputRef>
  </div>
</ng-template>

<nz-dropdown-menu #EditImg="nzDropdownMenu">
  <ul nz-menu class="p-6 b-radius-4" style="transform: translate(110px, -16px);">
    <li nz-menu-item (click)="InputRef.nativeElement.click()">
      <div class="d-flex ai-c">
        <i class="ph ph-pencil-simple mr-6"></i>
        {{ 'account_page.edit' | translate }}
      </div>
    </li>

    <li nz-menu-item>
      <div class="d-flex ai-c" (click)="deleteUserImage()">
        <i class="ph ph-trash-simple mr-6"></i>
        {{ 'account_page.delete' | translate }}
      </div>
    </li>
  </ul>
</nz-dropdown-menu>
