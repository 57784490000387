<div class="choose-plan-main-container">

  <sf-payment-banner/>

  @if (title) {
    <div class="pl-top-part">
      <h1>{{ title | translate }}</h1>
    </div>
  }

  <div class="sf-info-banner info ph ph-info mb-30">
    <div class="fs-16">
      <div>{{ 'pricing_page.no_recurring' | translate }}</div>
      <p [innerHTML]="'pricing_page.one_time_investment' | translate"></p>
    </div>
  </div>

  <div class="pl-bottom-part">
    <div class="pl-left-side">
      <div class="ls-top-part">
        <h3 class="basic">{{ 'pricing_page.basic' | translate }}</h3>
        <div class="free-pl">
          {{ 'pricing_page.free' | translate }}
          <p>{{ 'pricing_page.with_restrictions' | translate }}</p>
        </div>
      </div>

      <div class="ls-bottom-part">
        <div class="pl-includes">
          <h3>{{ 'pricing_page.plan_includes' | translate }}</h3>
          <ul>
            @for (item of appStateService.freeBillingPlanIncludes; track $index) {
              <li class="mb-4">{{ item.title | translate }}</li>
            }
          </ul>
        </div>
      </div>
    </div>

    <sf-premium-plans/>
  </div>
</div>

