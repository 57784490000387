<div class="page-not-fount-main-container">
  <sf-header/>
  <div class="page-not-fount-container">
    <img class="left-img" ngSrc="assets/img/not-found-left.svg"  width="340" height="364" alt="selfcv Not Found">
    <img class="right-img" ngSrc="assets/img/not-found-right.svg"  width="270" height="440" alt="selfcv Not Found">
    <img class="vector-img" src="assets/img/not-found-page-vector.svg" alt="selfcv vector">

    <div class="text-404">404</div>
    <strong>{{'page_not_found.oops' | translate}}</strong>
    <a class="sf-btn-primary" routerLink="/">{{'page_not_found.go_back' | translate}}</a>
  </div>
  <sf-footer/>
</div>
