<div class="home-page-main-container">

  <div class="img-vector-CV-wrapper">
    <section class="main-section">
      <div class="lef-side">
        <h1>{{ 'home_page.resume_builder' | translate }}</h1>

        <h2 class="mb-30 fs-16 l-height-initial">
          {{ 'home_page.pick_a_resume' | translate }}
        </h2>

        <a class="sf-btn-primary" routerLink="/dashboard">{{ 'home_page.build_my_resume' | translate }}</a>
      </div>

      <div class="right-side"></div>
    </section>

    <sf-why-use-us-section/>
  </div>

  <div class="S-logo-sections">
    <sf-resume-examples-section/>

    <div class="find-new-career-section">
      <section>
        <div class="small-desc">selfcv</div>

        <h2>{{ 'home_page.new_career' | translate }}</h2>

        <div class="mb-52">{{ 'home_page.start_building' | translate }}</div>

        <a class="sf-btn-primary w-250" routerLink="/cv-templates">{{ 'home_page.browse_resume' | translate }}</a>
      </section>
    </div>

    <div class="build-your-resume-section">
      <section>
        <div class="top-part">
          <div class="blue-small-desc">{{ 'home_page.find_solutions' | translate }}</div>

          <h2 [innerHTML]="'home_page.build_your_resume' | translate"></h2>

          <p>{{ 'home_page.just_follow' | translate }}</p>
        </div>

        <div class="body-part">
          @for (step of resumeBuildSteps; track $index) {
            <div class="step-item">
              <i class="{{step.icon}}"></i>
              <div>{{ step.title | translate }}</div>
              <p>{{ step.desc  | translate }}</p>
            </div>
          }

          <i class="sf-icon-icon-logo"></i>
        </div>

      </section>
    </div>

  </div>

  <sf-faq-section [faq]="appStateService.FAQObj.homePage"/>
  <sf-more-then-section/>
</div>
