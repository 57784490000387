<div class="billing-main-container">
  <div class="top-part">
    <h1>{{ 'account_page.billing_history' | translate }}</h1>

    <!--<div class="plans-list">

      <ng-container *ngIf="(appStateService.userSubscription | getPlan) as plan">
        <ng-container *ngIf="appStateService.userSubscription as sb">
          <div class="selected-plan">
            <div class="top-part">

              <div class="top-left">
                <strong>{{plan!.type}} {{plan!.name}}</strong>
                <div *ngIf="sb!.next_payment">
                  {{sb!.next_payment!.date | daysRemaining}} days remaining
                  ({{sb!.next_payment!.date | date:'dd MMM yyyy'}})
                </div>

                <div *ngIf="sb!.paused_at">
                  Expires at {{sb!.paused_from | daysRemaining}} days
                  <span>({{sb!.paused_from | date:'dd MMM yyyy'}})</span>
                </div>
              </div>

              <div class="top-right">
                <div>${{(plan!.monthlyPrice)}}/month{{sb.plan_id ? '' : 'ly'}}</div>
              </div>
            </div>

            <div class="bottom-part" *ngIf="sb.plan_id; else FreePLanREf">
              <button class="sf-white-btn" *ngIf="!sb!.paused_at" (click)="cancelSubscription()">
                Cancel Subscription
              </button>
              <button class="sf-white-btn" *ngIf="sb!.paused_at" (click)="reSubscribe()">
                Subscribe
              </button>
              <div class="canceled" *ngIf="sb!.paused_at">Canceled</div>
            </div>

            <ng-template #FreePLanREf>
              <div class="free-plan">
                <ul>
                  <li> One-page Resume</li>
                  <li> Predefined Layouts</li>
                  <li> Layouts One Resume</li>
                </ul>
              </div>

              <div class="free-plan-desc">Upgrade your plan to enable premium features.</div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let plan of billingPlans">
        <ng-container *ngIf="plan.id !== 'basic'">
          <div class="plan-item" *ngIf="plan.plan_id !== appStateService.userSubscription?.plan_id">

            <div class="plan-top">
              <div class="plan-top-left">
                <h4>{{plan.type}} {{plan.name}}</h4>
                <span>{{plan.periodDays}} days</span>
              </div>

              <div class="plan-top-right">
                <div>
                  <strong>${{plan.monthlyPrice}}/ </strong>
                  <span>{{plan.periodMonths}} months</span>
                </div>
                <div class="total-pr">
                  <span>Total: </span> <strong>${{plan.totalPrice}}</strong>
                </div>
              </div>
            </div>

            <div class="plan-bottom">
              <button
                class="sf-btn-primary small"
                *ngIf="appStateService.userSubscription?.subscription_id; else UpgradePlanBtnRef"
                (click)="onChangePlan(plan)">
                Change Plan
              </button>

              <ng-template #UpgradePlanBtnRef>
                <button class="sf-btn-primary small" (click)="onUpgradePlan(plan)">
                  Upgrade
                </button>
              </ng-template>

              <a class="sf-link" routerLink="/account/choose-plan">Learn more</a>
            </div>

          </div>
        </ng-container>
      </ng-container>

    </div>-->
  </div>

  <!--<div class="payments-part">
    <h4>Payment Method</h4>

    <div class="billing-list">
      <div
        class="payment-method"
        *ngIf="appStateService.userSubscription?.payment_information as pay; else PaymentEmptyRef">
        <span class="name-surname">{{appStateService.user?.firstName}} {{appStateService.user?.lastName}}</span>
        <span class="active-until">{{pay.expiry_date}}</span>
        <span class="card-numbers">
          <span>****</span>
          <span>****</span>
          <span>****</span>
          <span>{{pay.last_four_digits}}</span>
        </span>
        <i class="ph ph-pencil-simple" nz-tooltip="Update Payment Details" (click)="onUpdatePaymentDetails()"></i>
        <img ngSrc="assets/img/card.svg" width="348" height="222" alt="card">
      </div>
    </div>
  </div>-->

  <div class="billing-history-part">
    <!--    <h4>Billing History</h4>-->

    <div class="table-wrapper">
      <nz-table
        #basicTable
        [nzData]="transactions"
        [nzLoading]="tableLoadingState"
        [nzTotal]="transactions.length"
        [nzSize]="'middle'"
        nzShowPagination
        nzShowSizeChanger
        nzHideOnSinglePage>
        <thead>
        <tr>
          @for (column of tableJson; track column.label) {
            <th>{{ column.label | translate }}</th>
          }
          <!--<th>Actions</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
          @for (column of tableJson; track column.label) {
            <td>
              <div class="d-flex ai-c">
                <div
                  [innerHTML]="column.renderKey ? column.renderKey({column, data}) : $any(data)[column.keyName] | translate"
                ></div>
                @if (column.hasCopyClipBoard) {
                  <i
                    class="ph ph-copy c-pointer ml-4"
                    [nz-tooltip]="'tooltips.copy' | translate"
                    (click)="column.hasCopyClipBoardClicked?.({column, data})"
                  ></i>
                }
              </div>
            </td>
          }
          <!--<td nzRight>
            <div class="table-actions">
              &lt;!&ndash;<i class="ph ph-download-simple" [nz-tooltip]="'Download PDF'"></i>&ndash;&gt;
              &lt;!&ndash;<a [href]="data.receiptUrl" target="_blank">
                <i class="ph ph-file-text" [nz-tooltip]="'More Details'"  [nzTooltipMouseEnterDelay]="1"></i>
              </a>&ndash;&gt;

              @if (data.canCancel) {
                <button
                  class="sf-btn-delete small h-26 fs-12"
                  nz-tooltip="Cancel Payment"
                  (click)="onCancelPayment(data)">
                  Cancel
                </button>
              }

            </div>
          </td>-->
        </tr>
        </tbody>
      </nz-table>
    </div>

  </div>

</div>

<!--<ng-template #PaymentEmptyRef>
  <div class="payment-empty">
    <p>You haven't a payment method.</p>
  </div>
</ng-template>-->

<!--<ng-template #UpdatePaymentRef>
  <div class="update-payment">
    <iframe
      style="height: calc(100vh - 110px)"
      [src]="appStateService.userSubscription!.update_url | safe : 'resourceUrl'"
      frameborder="0"
      width="330"
    ></iframe>
  </div>
</ng-template>-->
