@switch (template.documentType) {
  @case (DocumentTypes.CV) {
    <sf-cv-template [forPrint]="true" [template]="$any(template)"/>
  }
  @case (DocumentTypes.COVER) {
    <sf-cover-letter [template]="$any(template)" [forPrint]="true"/>
  }
  @default {
    <sf-skeleton/>
  }
}
