import {Routes} from '@angular/router';
import * as generalPages from './pages';
import * as authPages from './layouts/auth/pages';
import * as pages from './layouts/main-layout/pages';
import * as accountPages from '@app/layouts/account/pages';
import {authGuard, DashboardGuard} from "@app/shared/guards";
import {ConfirmEmailComponent} from "@app/layouts/main-layout/pages/confirm-email/confirm-email.component";

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@app/layouts/main-layout/main-layout.component'),
    children: [
      {path: '', component: pages.HomePageComponent, title: 'selfcv | Free Resume Builder'},
      // {path: 'resume-templates', component: pages.ResumeTemplatesPageComponent, title: 'Resume Templates'},
      {path: 'cv-templates', component: pages.CvTemplatesPageComponent, title: 'selfcv | Free Resume Builder | CV Templates'},
      {path: 'cover-letters', component: pages.CoverLettersPageComponent, title: 'selfcv | Free Resume Builder | Cover Letters'},
      {path: 'pricing', component: pages.PricingPageComponent, title: 'Pricing'},
    ],
  },
  {
    path: 'auth',
    loadComponent: () => import('@app/layouts/auth/auth.component'),
    canActivate: [authGuard],
    children: [
      {path: '', redirectTo: 'sign-in', pathMatch: 'full'},
      {path: 'sign-in', component: authPages.SignInComponent, title: 'selfcv | Free Resume Builder | Sign In'},
      {path: 'sign-up', component: authPages.SignUpComponent, title: 'selfcv | Free Resume Builder | Sign Up'},
      {path: 'forgot-password', component: authPages.ForgotPasswordComponent, title: 'selfcv | Free Resume Builder | Forgot Password'},
      {path: 'reset-password', component: authPages.ResetPasswordComponent, title: 'selfcv | Free Resume Builder | Reset Password'},
    ]
  },
  {
    path: 'account',
    loadComponent: () => import('./layouts/account/account.component'),
    canActivate: [DashboardGuard],
    title: 'Account',
    children: [
      {path: '', redirectTo: 'user', pathMatch: 'full'},
      {path: 'user', component: accountPages.UserPageComponent, title: 'selfcv | Free Resume Builder | User Account'},
      {path: 'security', component: accountPages.ChangePasswordPageComponent, title: 'selfcv | Free Resume Builder | Security'},
      {path: 'billing-history', component: accountPages.BillingPageComponent, title: 'selfcv | Free Resume Builder | Billing History'},
      {path: 'choose-plan', component: accountPages.ChoosePlanComponent, title: 'selfcv | Free Resume Builder | Choose Plan'},
    ]
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./layouts/dashboard/dashboard.component'),
    canActivate: [DashboardGuard],
    title: 'selfcv | Free Resume Builder | Dashboard'
  },
  {
    path: 'documents/:documentId',
    loadComponent: () => import('./layouts/builder/builder.component'),
    canActivate: [DashboardGuard],
    title: 'selfcv | Free Resume Builder | Builder'
  },
  {path: 'terms-of-use', component: generalPages.TermsOfUseComponent, title: 'selfcv | Free Resume Builder | Terms Of Use'},
  {path: 'refund-policy', component: generalPages.RefundPolicyComponent, title: 'selfcv | Free Resume Builder | Refund Policy'},
  {path: 'confirm-email', component: ConfirmEmailComponent, title: 'selfcv | Free Resume Builder | Confirm Email'},
  {path: 'privacy-policy', component: generalPages.PrivacyPolicyComponent, title: 'selfcv | Free Resume Builder | Privacy Policy'},
  {path: 'contact-us', component: generalPages.ContactUsComponent, title: 'selfcv | Free Resume Builder | Contact Us'},
  {path: 'about-us', component: generalPages.AboutUsComponent, title: 'selfcv | Free Resume Builder | About Us'},
  {
    path: 'template-print/3cc7a492-8c98-4133-8b3d-a1bef3f1c9ce/:documentId',
    component: generalPages.TmPrintPageComponent
  },
  {path: '**', component: generalPages.PageNotFoundComponent}
];
