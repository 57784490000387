import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {httpContextDefaults} from "@app/shared/constants";
import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';


/*@Injectable()
export class ErrorLogInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = req.context.get(httpContextDefaults);

    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (params.errorLog) {
            console.error(err);
          }
          return throwError(err);
        })
      );
  }

}*/


export const errorLogInterceptor: HttpInterceptorFn = (req, next) => {
  const params = req.context.get(httpContextDefaults);

  return next(req)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        if (params?.errorLog) {
          console.error(err); // Log the error if errorLog is set in the params
        }
        return throwError(() => err); // Propagate the error to the next handler
      })
    );
};
