<sf-header/>
<div class="terms-main-container">
  <h1>Refund Policy</h1>

  <div class="content">
    <div class="left-side">
      <h2>Contents:</h2>

      <ul>
        <li
          *ngFor="let content of contentTitles"
          [class.active]="content.id === selectedId"
          (click)="scrollToElementById(content.id)">
          <span>{{content.id}}.</span> {{content.name}}
        </li>
        <li class="last-updated">Last updated: 14/06/2024</li>
      </ul>
    </div>

    <div class="right-side">
      <div class="block-item" *ngFor="let item of contentsInfo">
        <h2 [id]="item.title" [class.active]="item.id === selectedId">
          <span>{{item.id}}.</span> {{item.title}}
        </h2>
        <p [innerHTML]="item.info"></p>
      </div>
    </div>

  </div>
</div>
<sf-footer/>
