<sf-templates-section
  ngTitle="CV"
  [h2Text]="'templates_page.find' | translate"
  [pText]="'templates_page.explore' | translate"
  [templates]="appStateService.templates"
/>
<sf-why-use-us-section/>
<sf-resources-section [ngTitle]="'CV'" [resources]="resources"/>
<sf-resume-examples-section/>
<sf-faq-section [faq]="appStateService.FAQObj.cvTemplates"/>
<sf-more-then-section/>
