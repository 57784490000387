import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "../services";

export const authGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (authService.isAuthenticated) {
    router.navigate(['/']);
    return false;
  }
  return true;
}
