<div class="security-main-container">
  <div class="top-part">
    <h1>{{ 'account_page.change_password' | translate }}</h1>

    @if (appStateService.user?.emailVerified === false) {
      <div class="sf-info-banner info ph ph-info max-w-400">
        <div>
          <p class="fs-15" [innerHTML]="'account_page.email_verification' | translate"></p>
        </div>
      </div>
    } @else if (appStateService.user?.passwordLess === true) {
      <div class="sf-info-banner info ph ph-info max-w-400">
        <div>
          <p class="fs-15" [innerHTML]="'account_page.google' | translate"></p>
        </div>
      </div>
    } @else {
      <div class="bottom-part">

        @if (passwordFormState) {
          <div class="form-wrapper" *ngIf="!socialLoggedIn; else SocialLoggedInRef">
            <form [formGroup]="resetPassForm" #form="ngForm" (ngSubmit)="onSubmit()">

              <div class="sf-input-wr mb-20">
                <input
                  type="password"
                  class="sf-input"
                  autocomplete
                  [placeholder]="'placeholder.current_password' | translate"
                  formControlName="currentPassword"
                  sfTrim
                  [class.error]="form.submitted && resetPassForm.controls['currentPassword'].errors">

                <div class="sf-err-wr"
                     *ngIf="form.submitted && resetPassForm.controls['currentPassword'].errors as error">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    {{ 'validation.password_required' | translate }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.password_max_length'| translate:{
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['minlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.password_min_length'| translate: {
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>
                </div>
              </div>

              <div class="sf-input-wr mb-20">
                <input
                  type="password"
                  class="sf-input"
                  autocomplete
                  [placeholder]="'placeholder.new_password' | translate"
                  formControlName="newPassword"
                  sfTrim
                  [class.error]="form.submitted && resetPassForm.controls['newPassword'].errors">

                <div class="sf-err-wr" *ngIf="form.submitted && resetPassForm.controls['newPassword'].errors as error">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    {{ 'validation.password_required' | translate }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.password_max_length'| translate:{
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['minlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.password_min_length'| translate: {
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>
                </div>
              </div>

              <div class="sf-input-wr mb-20">
                <input
                  type="password"
                  class="sf-input"
                  autocomplete
                  [placeholder]="'placeholder.confirm_new_password' | translate"
                  formControlName="rp_password"
                  sfTrim
                  [class.error]="(form.submitted && resetPassForm.controls['rp_password'].errors) ||
                         (form.submitted &&
                          form.errors?.['mismatch'] &&
                          !resetPassForm.controls['newPassword']['errors'] &&
                          !resetPassForm.controls['rp_password']['errors'])">

                <div class="sf-err-wr" *ngIf="form.submitted && resetPassForm.controls['rp_password'].errors as error">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    {{ 'validation.password_required' | translate }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.password_max_length'| translate:{
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['minlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.password_min_length'| translate: {
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>
                </div>

                <div
                  class="sf-err-wr"
                  *ngIf="form.submitted &&
                 form.errors?.['mismatch'] &&
                 !resetPassForm.controls['newPassword']['errors'] &&
                 !resetPassForm.controls['rp_password']['errors']">
                  <div class="sf-input-err">
                    <i class="ph ph-warning"></i>
                    {{ 'validation.passwords_do_not_match' | translate }}
                  </div>
                </div>
              </div>

              <div class="btn-part">
                <button class="sf-btn-tertiary mr-12" type="button" (click)="onCancel()">
                  {{ 'cancel' | translate }}
                </button>
                <button class="sf-btn-primary" [class.loading]="loaderState">
                  {{ 'update' | translate }}
                </button>
              </div>

            </form>
          </div>
        } @else {
          <div class="pass-to-change">
            <div class="left-side">
              <div>{{ 'account_page.password' | translate }}</div>
              <p *ngIf="appStateService.user?.passwordChangedAt">
                {{ 'account_page.last_change' | translate }}
                <span>{{ appStateService.user!.passwordChangedAt | date }}</span>
              </p>
            </div>

            <button class="sf-btn-primary w-210" (click)="passwordFormState = true">
              {{ 'account_page.change_password_btn' | translate }}
            </button>
          </div>
        }
      </div>
    }
  </div>
</div>

<ng-template #SocialLoggedInRef>
  <div class="sf-info-banner info ph ph-info mb-30">
    <div>
      <div>{{ 'account_page.password_change_not_allowed' | translate }}</div>
      <p>{{ 'account_page.logged_in' | translate }}</p>
    </div>
  </div>

  <div class="social-logged-in">
    <p>{{ 'account_page.follow_these_steps' | translate }}</p>

    <ul>
      <li class="sf-link" (click)="onSocialReset()">{{ 'account_page.forgot_password_page' | translate }}</li>
      <li>{{ 'account_page.instructions_email' | translate }}</li>
    </ul>
  </div>
</ng-template>

