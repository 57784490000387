import {NgIf} from "@angular/common";
import {Subject, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {DocumentTypes} from "@app/shared/enums";
import {Template} from "@app/shared/interfaces";
import {TemplateService} from "@app/shared/services";
import {SkeletonComponent} from "@app/templates/componenets";
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {CvTemplateComponent} from "@app/templates/cv-template/cv-template.component";
import {CoverLetterComponent} from "@app/templates/cover-letter/cover-letter.component";

@Component({
  selector: 'sf-tm-print-page',
  standalone: true,
  imports: [
    CvTemplateComponent,
    NgIf,
    CoverLetterComponent,
    SkeletonComponent
  ],
  templateUrl: './tm-print-page.component.html',
  styleUrl: './tm-print-page.component.scss'
})
export class TmPrintPageComponent implements OnInit, OnDestroy {
  public readonly activatedRoute = inject(ActivatedRoute);
  private readonly templateService = inject(TemplateService);

  protected readonly DocumentTypes = DocumentTypes;
  protected template: Template = {} as Template;

  private readonly destroy$ = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.getTemplate();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private getTemplate() {
    const documentId: string = this.activatedRoute.snapshot.params['documentId'];
    this.templateService.getTemplate(documentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (template) => {
          this.template = template;
          console.log(template);
        },
        () => {
          // this.nzMessageService.error(err?.error?.reason || err?.error?.error || 'Something went wrong');
          // this.router.navigate(['dashboard']);
        }
      );
  }

}
