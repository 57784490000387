import {environment} from "@env";
import {HttpInterceptorFn} from '@angular/common/http';
import {httpContextDefaults} from "@app/shared/constants";

export const urlPrefixInterceptor: HttpInterceptorFn = (req, next) => {
  const params = req.context.get(httpContextDefaults);

  // Skip prefix for JSON files
  if (req.url.includes('.json')) {
    return next(req);
  }

  // Skip prefix if the context requests it
  if (params?.skipPrefix) {
    return next(req);
  }

  // Clone and modify the request
  const modifiedReq = req.clone({
    url: `${environment.endpoint}/${req.url}`,
  });

  return next(modifiedReq);
};

