import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from "../services";
import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.isAuthenticated) {
      this.router.navigate(['auth'], {queryParams: {...route.queryParams, returnUrl: state.url}});
      return false;
    }

    return this.authService.getMe().pipe(map(() => true));
  }

}
