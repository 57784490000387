import {DatePipe, NgForOf} from "@angular/common";
import {scrollToElement} from "@app/shared/helpers";
import {Component, OnInit} from '@angular/core';
import {FooterComponent, HeaderComponent} from "@app/shared/ui-kits";

@Component({
  selector: 'sf-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.scss'],
  imports: [
    NgForOf,
    DatePipe,
    FooterComponent,
    HeaderComponent
  ],
  standalone: true
})
export class RefundPolicyComponent implements OnInit {

  protected selectedId = 1;
  protected contentTitles = [
    {id: 1, name: 'Interpretation and Definitions'},
    {id: 2, name: 'Refund Policy '},
    {id: 3, name: 'Chargebacks'},
  ];
  protected contentsInfo: { id: number, title: string, info: string }[] = [
    {
      id: 1,
      title: 'Interpretation and Definitions',
      info:'<div class="sf-policy-text-justify">' +
        '<p class="sf-policy"><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>' +
        '<p class="sf-policy"><strong>Company</strong> (referred to as either "the Company", “Selfcv”, ' +
        '"We", "Us" or "Our" in this Agreement) refers to <strong>SELFCV LLC</strong>' +
        ', a company organised and existing under the laws of the Republic of Armenia, with its registered ' +
        'address at: Nikoghayos Tigranyan blind alley 1,  0014, Yerevan, Armenia.</p>' +
        '<p class="sf-policy"><strong>Country</strong> refers to Armenia.' +
        '</p>' +
        '<p class="sf-policy"><strong>Service</strong> refers to the Website and the services thereof.</p>' +
        '<p class="sf-policy"><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>' +
        '<p class="sf-policy"><strong>Website</strong> refers to the official website of Selfcv, accessible from https://www.selfcv.com.</p>' +
        '<p class="sf-policy"><strong>YOU</strong> means the individual accessing or using the Service, or the company, ' +
        'or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></div>'
    },
    {
      id: 2,
      title: 'Refund Policy',
      info: '<p>\n' +
        '        We reserve the right to refuse or cancel <strong>Your Order</strong> at any time for certain reasons including but not limited to:\n' +
        '    </p>\n' +
        '    <ul class="sf-policy-list">\n' +
        '        <li>Errors in the description or prices for Goods</li>\n' +
        '        <li>Errors in <strong>Your Order</strong></li>\n' +
        '    </ul>\n' +
        '<p><strong>Selfcv</strong> has no obligation to provide a refund or credit for reasons including but not limited to, if you:\n' +
        '\n' +
        '    <ul class="sf-policy-list">\n' +
        '        <li>Have changed your mind about a purchased product.\n' +
        '        <li>Bought a product by mistake.\n' +
        '        <li>Do not have sufficient expertise to use the product.\n' +
        '        <li>Do not have any need to use the product.\n' +
        '        <li>Have engaged in activities that <strong>Selfcv</strong> deems suspicious or fraudulent.</p>' +
        '    </ul>\n' +
        '    <p>\n' +
        '        We also reserve the right to refuse or cancel <strong>Your Order</strong> if fraud or an unauthorized or illegal transaction is suspected.\n' +
        '    </p>\n' +
        '    <h3  class="sf-policy"><strong>Your Order Cancellation Rights</strong></h3>\n' +
        '    <p>\n' +
        '        Any Goods you purchase can only be canceled in accordance our Terms and Conditions and Our Refund Policy.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        All payments on <strong>Selfcv</strong> are being processed by third-party payment processors that we believe are in full compliance with the requirements of applicable legislation. However, they may have Terms of Use other than described in this agreement, so we encourage you to be aware when you leave our site and to read the Terms of Use of each and every website that collects personally identifiable information.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        In addition, <strong>Selfcv</strong> is not responsible for any differences caused by change of currency exchange rates you were charged by third parties.\n' +
        '    </p>'
    },
    {
      id: 3, title: 'Chargebacks',
      info: '<strong>What is a Chargeback?</strong> ' +
        '<div class="sf-policy">' +
        'A chargeback occurs when a customer disputes a charge with their bank or credit card company, resulting in the reversal of the transaction.</div> ' +
        '<strong class="sf-policy">Our Chargeback Policy</strong> <div>' +
        '    <ul class="sf-policy">\n' +
        '        <li>We take chargebacks seriously and consider them a last resort for resolving disputes. We encourage customers to contact us directly to resolve any issues with their purchase.' +
        '        <li>If you believe there has been an unauthorized transaction or if there is a problem with your order, please contact our customer service team immediately at <a class="sf-link" href="mailto: support@selfcv.com"> support@selfcv.com</a>.' +
        '    </ul>\n' +
        '</div>' +
        '<strong class="sf-policy">Chargeback Process</strong> <div>' +
        '    <ul class="sf-policy">\n' +
        '        <li>If a chargeback is filed, we will provide the necessary documentation to the bank or credit card company to dispute the chargeback.' +
        '        <li>We reserve the right to pursue legal action and charge additional fees to cover the costs associated with the chargeback process.' +
        '    </ul>\n' +
        '</div>' +

        '<strong class="sf-policy">Preventing Chargebacks</strong> <div>' +
        '<p class="sf-policy">To avoid chargebacks, we recommend that customers</p>' +
        '    <ul class="sf-policy-list">\n' +
        '        <li>Review their purchase details carefully before completing the transaction.' +
        '        <li>Contact our customer support for any questions or concerns about their order.' +
        '        <li>Keep records of all transactions and communications with us.' +
        '    </ul>\n' +
        '</div>' +

        '<strong>Contact Us</strong> <div class="sf-policy">' +
        '<p>If you have any questions or concerns about our refund policy or chargeback policy, please contact us at:</p>' +
        '    <ul class="sf-policy">\n' +
        '        <li>Email: <a class="sf-link" href= "mailto: support@selfcv.com"> support@selfcv.com</a>' +
        '        <li>Address: Nikoghayos Tigranyan blind alley 1, 0014, Yerevan, Armenia' +
        '    </ul>\n' +
        '</div>' +
        '<strong> We are committed to providing excellent customer service and will do our best to resolve any issues to your satisfaction. </strong>'
    },
  ];

  ngOnInit() {
  }

  protected scrollToElementById(id: number): void {
    this.selectedId = id;
    const title = this.contentsInfo.find((info) => info.id === id)?.title;
    const element = document.getElementById(title || '');
    scrollToElement(element!, 120);
  }

}
