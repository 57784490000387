import * as sections from '../sections';
import {Component, inject} from '@angular/core';
import {Resource} from '@app/shared/interfaces';
import {TranslateModule} from "@ngx-translate/core";
import {AppStateService} from "@app/shared/services";

@Component({
  selector: 'sf-cover-letters-page',
  templateUrl: './cover-letters-page.component.html',
  styleUrls: ['./cover-letters-page.component.scss'],
  imports: [
    sections.MoreThenSectionComponent,
    sections.ResumeExamplesSectionComponent,
    sections.WhyUseUsSectionComponent,
    sections.ResourcesSectionComponent,
    sections.FAQSectionComponent,
    sections.TemplatesSectionComponent,
    TranslateModule
  ],
  standalone: true
})
export class CoverLettersPageComponent {
  public readonly appStateService = inject(AppStateService);
  public readonly resources: Readonly<Resource[]> = [
    {
      title: 'cover_letters_page.resources.what_is',
      content: 'cover_letters_page.resources.what_is_desc',
      image: 'assets/img/illustrations/cover-letter.svg'
    },
    {
      title: 'cover_letters_page.resources.what_include',
      content: 'cover_letters_page.resources.what_include_desc',
      image: 'assets/img/illustrations/cover-letter-content.svg'
    },
    {
      title: 'cover_letters_page.resources.what_include_r',
      content: 'cover_letters_page.resources.what_include_r_desc',
      image: 'assets/img/illustrations/cover-letter-include.svg',
    },
    {
      title: 'cover_letters_page.resources.how_long',
      content: 'cover_letters_page.resources.how_long_desc',
      image: 'assets/img/illustrations/cover-letter-length.svg'
    },
    {
      title: 'cover_letters_page.resources.purpose',
      content: 'cover_letters_page.resources.purpose_desc',
      image: 'assets/img/illustrations/resume-versions.svg',
    },
    {
      title: 'cover_letters_page.resources.are_obsolete',
      content: 'cover_letters_page.resources.are_obsolete_desc',
      image: 'assets/img/illustrations/cover-letter-need.svg',
    }
  ];
}


